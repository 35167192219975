<script setup>
import UiLinkTag from '@/components/ui/UiLinkTag.vue';
import CIcon from '@/components/common/CIcon.vue';

const props = defineProps({
  to: {
    type: String,
    default: undefined,
  },
  target: {
    type: String,
    default: undefined,
  },
  isStaticByDefault: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    default: undefined,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: undefined,
  },
  theme: {
    type: String,
    default: undefined,
    validator(theme) {
      return ['grey', 'white', 'transparent'].includes(theme);
    },
  },
});

const emit = defineEmits(['click']);

const emitClick = (e) => {
  emit('click', e);
};
</script>
<template>
  <UiLinkTag
    :to="props.to"
    :target="props.target"
    :type="props.type"
    :is-static-by-default="props.isStaticByDefault"
    class="ui-button-icon"
    :is-disabled="isDisabled"
    :class="{
      [`ui-button-icon--theme--${theme}`]: props.theme,
      'ui-button-icon--disabled': props.isDisabled,
    }"
    @click="emitClick"
  >
    <slot>
      <CIcon
        v-if="props.icon"
        :name="props.icon"
        class="ui-button-icon__icon"
      />

      <span v-if="props.text" class="ui-button-icon__font">{{
        props.text
      }}</span>
    </slot>
  </UiLinkTag>
</template>

<style scoped lang="scss">
.ui-button-icon {
  $parent: &;

  display: inline-flex;
  gap: em(8);
  align-items: center;
  justify-content: center;
  min-height: em(36);
  padding: em(5) em(16);
  text-align: center;
  background-color: $color-yellow-3;
  border-radius: em(12);
  transition: $time-default ease-out;
  transition-property: background-color, color;

  @include hover {
    background-color: $color-yellow-2;
  }

  &__font {
    font-size: em(14);
    font-weight: 600;
    line-height: 1;
  }

  &__icon {
    flex-shrink: 0;
    width: em(24);
    height: em(24);
    transition: $time-default ease-out;
    transition-property: color;
  }

  &--disabled {
    color: $color-grey-2 !important;
    pointer-events: none;
    touch-action: none;
    background-color: $color-grey-1 !important;
  }

  &--theme {
    &--grey {
      background-color: $color-grey-1;

      @include hover {
        color: $color-blue-1;
        background-color: $color-grey-1;
      }
    }

    &--white {
      background-color: $color-white;

      @include hover {
        color: $color-blue-1;
        background-color: $color-white;
      }
    }

    &--transparent {
      background-color: transparent;

      @include hover {
        color: $color-blue-1;
        background-color: transparent;
      }
    }
  }
}
</style>
